import React from "react"
import Client from "../../../../components/general/Client"
import { ContainerElement } from "./styled"

export const NedAir: React.FC = () => {
  return (
    <ContainerElement>
      <Client
        title={"Ned Air"}
        description={
          "Ned Air is een toonaangevende fabrikant van luchtbehandelingsystemen, warmteterugwinunits en dakventilatoren. Vanuit onze passie voor techniek creërt Ned Air voor iedere situatie een duurzame luchttechnische oplossing. machine."
        }
      />
    </ContainerElement>
  )
}
