import React from "react"
import { NedAir as NedAirComponent } from "../components/pages/clients/NedAir/NedAir"
import Layout from "../components/layout"

const NedAir = () => (
  <Layout>
    <NedAirComponent />
  </Layout>
)

export default NedAir
